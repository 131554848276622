import React from "react"
import Headings from "../components/headings"

export const Iframe = video => {
  const {
    headings,
    src,
    title,
    width,
    height,
    bg = "uk-background-default",
  } = video.video

  //console.log("Video: ", video)

  return (
    <div className={`uk-section ${bg} uk-padding-remove`}>
      <div className="uk-container uk-margin-small-top">
        <Headings
          title={headings.title}
          description={headings.description}
          bg={bg}
        />

        <div className="uk-container uk-container-small uk-margin-small-top uk-margin-medium-bottom">
          <iframe
            src={src}
            width={width}
            height={height}
            title={title}
            style={{ border: "none", overflow: "hidden" }}
            //scrolling="no"
            //frameBorder="0"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </div>
    </div>
  )
}
