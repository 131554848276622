import React from "react"
import TestimonialCard from "./testimonial-card"

const TestimonialSliderBackgroundImage = ({
  BackgroundImage,
  testimonialCards,
}) => {
  //console.log("testimonial Card: ", testimonialCards)
  return (
    <section className="uk-cover-container overlay-wrap">
      <img
        src={BackgroundImage}
        data-sizes="100vw"
        alt="Chulie with the dogs at the shelter"
        data-uk-cover="true"
        data-uk-img="true"
      />
      <div className="uk-container uk-position-z-index uk-position-relative uk-section uk-section-xlarge uk-light">
        <div className="uk-grid uk-flex-right">
          <div
            className="uk-width-2-5@m removeOpacity"
            data-uk-parallax="y: 50,-50; easing: 0; media:@l"
          >
            <h6>FEATURED SOCIAL MEDIA COMMENTS</h6>
            <div
              className="uk-position-relative uk-visible-toggle "
              data-uk-slider="easing: cubic-bezier(.16,.75,.47,1)"
            >
              <ul className="uk-slider-items uk-child-width-1-1 ">
                {testimonialCards.map((testimonialCard, index) => {
                  //console.log("Article: ", article)
                  return (
                    <TestimonialCard
                      key={index}
                      testimonialCard={testimonialCard}
                    />
                  )
                })}
              </ul>
              <ul className="uk-slider-nav uk-dotnav uk-margin-top">
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestimonialSliderBackgroundImage
