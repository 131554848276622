import React from "react"

const TestimonialCard = ({ testimonialCard }) => {
  //console.log("testimonial Card: ", testimonialCard)
  return (
    <li>
      <div>
        <h2
          className="uk-margin-small-top"
          style={{
            textShadow: "2px 2px 4px #000000",
            fontSize: "1.3rem",
          }}
        >
          {testimonialCard.content.data.content}
        </h2>
        <p style={{ color: "#FFFFFF" }}>{testimonialCard.name}</p>
      </div>
    </li>
  )
}

export default TestimonialCard
